<template>
    <!-- 
        Список співробітників
        - дозволяє переглянути список співробітників ( з пошуком по імені/номеру телефона), та з фільтрацією по статусу та посаді
        - відкрити картку співробітника
        - викликати створення співробітника
        - та інше :)
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - :removeitem - параметр-прапорець, що дозволяє чи забораняє видалення записів з таблиці
        - :tabs - параметр-об'єкт із конфігурацією вкладок таблиці
        - :addtabs - параметр-прапорець, що вказує, чи відображати кнопку для додавання вкладок
        - @addCategory - подія, що спрацьовує при додаванні нової категорії
        - @removeCategory - подія, що спрацьовує при видаленні категорії
        - @remove - подія, яка спрацьовує при видаленні запису з таблиці
        - @create - подія, яка спрацьовує при створенні нового співробітника
        - @open - подія, яка спрацьовує при відкритті картки співробітника
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
        - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
        - @changeTab - подія, яка спрацьовує при зміні вкладки таблиці
    -->
    <tablecustom
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['701'] || this.perms['2000']" 
        :removeitem="this.perms['704'] || this.perms['2000']"
        :tabs="othertabs"
        :addtabs="true"
        @addCategory="showFilterBox = true"
        @removeCategory="removeCategory"
        @remove="remove"
        @create="showModalAddNew = true" 
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @changeTab="changeTab"
    />

    <!-- 
       Виклик відкриття картки співробітника
       - для відкриття застосовується перевірка значення showModal
       - @close - подія, яка спрацьовує при закритті картки
       - :objcard - параметр-об'єкт з даними по співробітнику
       - :ifPrevopen - параметр, який передає статус відображення стрілочки назад
       - :ifNextopen - параметр, який передає статус відображення стрілочки вперед
       - @prevOpen - подія, яка спрацьовує коли натискаєш на стрілочку назад
       - @nextOpen - подія, яка спрацьовує коли натискаєш на стрілочку вперед
    -->
    <viewcard 
        v-if="showModal == true" 
        @close="closeModal" 
        :objcard="objcard"
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
    ></viewcard>

    <!-- 
        Виклик вікна створення картки співробітника
        - для відкриття застосовується перевірка значення showModalAddNew
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createBox 
        v-if="showModalAddNew == true" 
        @close="closeModal"
    ></createBox>
    
    <!-- 
        Компонент табів/вкладок
        - для відкриття застосовується перевірка значення showFilterBox
        - @close - подія, яка спрацьовує при закритті модального вікна
        - @changeTabs - подія, яка спрацьовує на збереження параметрів табів
        - :othertabs - параметр з даними табів/вкладок
    -->
    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @changeTabs="changeTabs"
        :othertabs="othertabs"
    >
    </filterTabs>

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import filterTabs from './filterTabs/index.vue' // вкладки/таби таблиці
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewcard from './view/index'; // картка співробітника
import createBox from './newuser.vue' // модальне вікно створення співробітника
import { mutateWorkerJob, mutateWorkersAccount } from '@/usabilityScripts/globalMutate.js'
import { Users } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from "@/store"; // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Users();

export default {
    components: {
        PageHeader,
        tablecustom,
        viewcard,
        createBox,
        filterTabs
    },
    data() {
        return {
            title: this.$t('users'), // Заголовок
            showFilterBox: false, // Прапорець відображення вікна фільтрів
            showModal: false, // Прапорець відображення вікна співробітника
            showModalAddNew: false, // Прапорець відображення вікна для додавання співробітника
            ifNextopen: "", // Прапорець статусу відображення кнопки "<"
            ifPrevopen: "", // Прапорець статусу відображення кнопки ">"
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "workers"
            },
            objParams:{ // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerStatus: '',
                workerJob: ''
            },
            columns: [ 
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: this.$t('name'),
                    text: "workerName", 
                    align: "left", 
                    status: true 
                },
                {
                    name: this.$t("Position"),
                    text: "workerJob",
                    align: "left",
                    status: false,
                    mutate: (item) => this.mutateWorkerJob(item)
                },
                {
                    name: this.$t("phone_num"),
                    text: "workerPhone",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("mail"),
                    text: "workerEmail",
                    align: "right",
                    status: true,
                    mutate: (item) => item ? item : '-'
                },
                {
                    name: this.$t("Status"),
                    text: "workerStatus",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutateStatusWorker(item).name,
                    mutateClass: (item) => this.mutateStatusWorker(item).color
                }
            ],
            rows: [], // Дані для відображення у таблиці
            objPages: {},  // Об'єкт з даними про сторінки для розподілу даних по сторінках
            objcard: {}, // Об'єкт з даними співробітника для відображення в модальному вікні
            othertabs: [] // Збережені дані табів 
        };
    },
    created() {
        this.getdata(); // Виклик функції отримання даних при створенні компонента
    },
    methods: {
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку співробітників з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список співробітників) та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.getAllUsers(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку співробітників'; 
                    var statusRequest = result.status;
                    var api = 'getAllUsers';
                    var fileRequest = 'src/views/users/users.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open: function(e){
            /**
                Функція для відкриття модального вікна з інформацією про співробітника за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор робітника або об'єкт з інформацією про робітника.

                Викликає API для отримання інформації про співробітника.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка співробітника, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка співробітника, після поточної відкритої картки
            */

            // Визначення ідентифікатора робітника.
            var id = e.workerId ? e.workerId : e;

            // Перевірка, чи робітник є першим у списку для відкриття вікна "Попередній робітник".
            this.ifPrevopen = id == this.rows[0].workerId ? false : true; 

            // Перевірка, чи робітник є останнім у списку для відкриття вікна "Наступний робітник".
            this.ifNextopen = id == this.rows[this.rows.length-1].workerId ? false : true;

            apiServe.getUser(id).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                } else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка відкриття картки співробітника'; 
                    var statusRequest = result.status;
                    var api = 'getUser';
                    var fileRequest = 'src/views/users/users.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal та showModalAddNew в false, та викликає функцію getdata для оновлення даних таблиці.
            */

            this.showModal = false;
            this.showModalAddNew = false;
            this.getdata(this.objParams.page);
        },
        mutateStatusWorker(e){
            /*
                Функція для застосування перекладу до статусу картки співробітника
            */

            return mutateWorkersAccount(e)
        },
        mutateWorkerJob(e){
            /*
                Функція для застосування перекладу до посади картки співробітника
            */

            return mutateWorkerJob(e)
        },
        prevOpen(e){
            /*
                Функція для відкриття модального вікна попереднього співробітника у списку
                Отримує ідентифікатор поточного співробітника, знаходить ідентифікатор попереднього співробітника в списку та викликає функцію open для відображення інформації про співробітника.
            */

            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].workerId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].workerId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            /*
                Функція для відкриття модального вікна наступного співробітника у списку
                Отримує ідентифікатор поточного співробітника, знаходить ідентифікатор наступного співробітника в списку та викликає функцію open для відображення інформації про співробітника.
            */

            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].workerId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].workerId;
                }
            }
            this.open(next_id);
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за іменем та номером телефону
            */

            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.workerJob = '',
                this.objParams.workerStatus = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                this.objParams[name] = value;
                this.getdata();
            }
        },
        clearFilter(){
            /*
                Функція для очищення значень фільтрації та виклику функції getdata
            */

            this.objParams.search = '';
            this.objParams.workerJob = '';
            this.getdata();
        },
        changeTab(name, value){
            /*
                Функція для зміни активного табу та виклику функції отримання даних getdata з новими параметрами фільтрації.
            */
            var arrFilter = ['search','workerStatus', 'workerJob'];
            for(var item in arrFilter){
                if(arrFilter[item] == name){
                    this.objParams[name] = value
                } else {
                if(name == 'pr1') {
                    this.objParams.search = '',
                    this.objParams.workerStatus = '',
                    this.objParams.workerJob = '';
                    return
                }
                this.objParams[arrFilter[item]] = '';
                }
            }
            this.getdata();
        },
        changeTabs(item){
            /*
                Функція для зміни табів та виклику функції отримання даних getdata з новими параметрами фільтрації.
            */
            for(var item in this.items){
                this.othertabs.push(this.items[item])
            }
            // this.othertabs.push(item);
            localStorage.setItem("Workerscheckbox", JSON.stringify(this.othertabs));
            localStorage.setItem("resultWorkers", this.othertabs.length)
            this.getdata()
        },
        removeCategory(e, i){
            /*
                Функція для видалення категорії (табу) та оновлення збережених даних в локальному сховищі.
            */
            this.othertabs.splice(i, 1)
            localStorage.setItem("Workerscheckbox", JSON.stringify(this.othertabs));
        },
    },
    computed: {
        /*
            Властивість, яка повертає дані за користувача зі збережених даних у глобальному стору.
        */
        user() {
            return storeS.userbase
        },
        /*
            Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
        */
        perms() {
            return storeS.perms
        }
    },
    mounted() {
        // Завантаження збережених даних табів з локального сховища.
        this.othertabs = localStorage.getItem("Workerscheckbox") ? JSON.parse(localStorage.getItem("Workerscheckbox")) : [];

        // Cлухання події saveUser для оновлення даних після змін з карткою користувача.
        this.eventBus.off('saveUser')
        this.eventBus.on('saveUser', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    }
};
</script>

<style scoped>
    .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
    }
</style>
